import * as React from 'react'
import { format } from 'date-fns'
// import * as R from 'ramda'
import { string, number, oneOfType, bool, shape } from 'prop-types'
import classNames from 'classnames'
import Text from '@/utils/Text'
import useDateField from '@/hooks/useDateField'
import DateInput from './DateInput'
import reducer from './reducer'
import { toNum } from './utils'
import styles from './DateField.module.css'

function keyHandle(e, fn) {
	const {
		keyCode,
		target: { value: date }
	} = e

	const UP = keyCode === 38
	const DOWN = keyCode === 40
	if (UP || DOWN) {
		const v = UP ? toNum(date) + 1 : toNum(date) - 1
		fn(v.toString())
	}
}

function DateField({
	today = Date.now(),
	label,
	className,
	value,
	error,
	name = 'date',
	validate,
	minYear,
	maxYear
}) {
	const { ref, setValue } = useDateField(name, {
		validate,
		label
	})

	const [y, m, d] = value ? value.split('-') : ['', '', '']
	const monthInputRef = React.useRef()
	const yearInputRef = React.useRef()
	const [state, dispatch] = React.useReducer(reducer, {
		day: d,
		month: m,
		year: y,
		today: format(today, 'yyyy-LL-dd')
	})

	React.useEffect(() => {
		if (state.day || state.month || state.year) {
			setValue(name, `${state.year}-${state.month}-${state.day}`)
		}
	}, [name, setValue, state.day, state.month, state.year])

	const onDayChange = e => {
		const { value: date } = e.target
		const maxNum = state.maxDay >= 30 ? 3 : 2
		const input = monthInputRef.current
		dispatch({ type: 'SET_DAY', payload: date })

		if (
			((toNum(date) > maxNum && date.length === 1) ||
				(date.length === 2 && toNum(date) <= state.maxDay)) &&
			input
		) {
			input.focus()
		}
	}

	const onMonthChange = e => {
		const { value: date } = e.target
		const input = yearInputRef.current
		const num = toNum(date)

		dispatch({ type: 'SET_MONTH', payload: date })
		if (num > 1 && num <= 12 && input) {
			input.focus()
		}
	}

	const onYearChange = e => {
		const { value: date } = e.target
		const currentYear = new Date().getFullYear()

		if (maxYear && minYear && date.toString().length === 4) {
			if (maxYear >= date && date >= minYear) {
				dispatch({ type: 'SET_YEAR', payload: date })
			}
		} else if (maxYear && date.toString().length === 4) {
			if (maxYear >= date && date >= currentYear) {
				dispatch({ type: 'SET_YEAR', payload: date })
			}
		} else {
			dispatch({ type: 'SET_YEAR', payload: date })
		}
	}

	return (
		<fieldset
			ref={ref}
			className={className}
			role="group"
			aria-describedby={`${name}-hint`}
		>
			{label && (
				<Text
					className="text-mob-lg md:text-lg mb-lg md:mb-xl font-h-light"
					as="legend"
				>
					{label}
				</Text>
			)}
			<div className="flex items-end">
				<DateInput
					data-testid={`${name}-day-input`}
					value={state.day}
					label="Day"
					name={`${name}-day`}
					id={`${name}-day`}
					placeholder="DD"
					wrapperClass={classNames(styles.input, 'mr-lg')}
					error={error}
					maxLength={2}
					onChange={onDayChange}
					onKeyUp={e => {
						keyHandle(e, payload => {
							dispatch({ type: 'SET_DAY', payload })
						})
					}}
					onBlur={() => dispatch({ type: 'PAD_DAY' })}
				/>
				<DateInput
					ref={monthInputRef}
					data-testid={`${name}-month-input`}
					value={state.month}
					label="Month"
					name={`${name}-month`}
					id={`${name}-month`}
					placeholder="MM"
					wrapperClass={classNames(styles.input, 'mr-lg')}
					error={error}
					maxLength={2}
					onChange={onMonthChange}
					onBlur={() => dispatch({ type: 'PAD_MONTH' })}
					onKeyUp={e => {
						keyHandle(e, payload => {
							dispatch({ type: 'SET_MONTH', payload })
						})
					}}
				/>
				<DateInput
					ref={yearInputRef}
					data-testid={`${name}-year-input`}
					value={state.year}
					label="Year"
					name={`${name}-year`}
					id={`${name}-year`}
					placeholder="YYYY"
					wrapperClass={classNames(styles.input, 'mr-lg')}
					error={error}
					maxLength={4}
					onChange={onYearChange}
					onKeyUp={e => {
						keyHandle(e, payload => {
							dispatch({ type: 'SET_YEAR', payload })
						})
					}}
				/>

				<div id={`${name}-hint`} className="hidden md:block pb-sm text-grey-80">
					<Text>e.g. 28 08 1978</Text>
				</div>
			</div>
		</fieldset>
	)
}

DateField.propTypes = {
	today: number,
	value: string,
	error: oneOfType([string, bool]),
	className: string,
	name: string.isRequired,
	label: oneOfType([string, bool]),
	validate: shape({
		required: oneOfType([
			shape({
				value: bool,
				message: string
			}),
			bool
		])
	}),
	minYear: number,
	maxYear: number
}

export default DateField
