import * as React from 'react'
import { shape, string } from 'prop-types'
import { useAsync } from 'react-async-hook'
import { useDispatch } from 'react-redux'
import Loading from '@/components/screens/Loading'
import PageError from '@/components/screens/PageError'
import Text from '@/utils/Text'
import * as restAPI from '@/utils/endpoints'
import fetchApi from '@/utils/fetchApi'
import * as m from '@/utils/messages'

const LOADING = 'LOADING'
const LOADED = 'LOADED'
const ERROR = 'TERMS'

const fetchValidation = async token =>
	fetchApi(restAPI.CHECK_TOKEN, {
		method: 'POST',
		body: JSON.stringify({ token, path: 'password-reset' })
	})

function withValidResetUrl(Component) {
	const Wrapper = ({ location, ...props }) => {
		const [, token] = location.pathname.split('/').filter(Boolean)
		const [view, setView] = React.useState(LOADING)
		const dispatch = useDispatch()

		const SHOW_LOADING = view === LOADING
		const SHOW_COMPONENT = view === LOADED
		const SHOW_ERROR = view === ERROR
		const fetchDetails = useAsync(fetchValidation, [token])

		React.useEffect(() => {
			if (fetchDetails.status === 'success') {
				const { error, status, isValid } = fetchDetails.result
				if (typeof error !== 'undefined' || status === 'error' || !isValid) {
					setView(ERROR)
					return
				}

				setView(LOADED)
			}
		}, [fetchDetails.status, fetchDetails.result, dispatch])

		return (
			<>
				{SHOW_LOADING && <Loading>Loading profile</Loading>}
				{SHOW_COMPONENT && (
					<Component location={location} {...props} id={token} />
				)}
				{SHOW_ERROR && (
					<PageError case="error">
						<Text as="h2" className="text-2xl font-h-light">
							{m.ISSUE_REQUESTING_RESET_LINK}
						</Text>
					</PageError>
				)}
			</>
		)
	}

	Wrapper.propTypes = {
		location: shape({ pathname: string.isRequired }).isRequired
	}

	return Wrapper
}

export default withValidResetUrl
