import * as React from 'react'
import { useFormContext } from 'react-hook-form/dist/react-hook-form.ie11'
import { datePattern } from '@/utils/constants'

function useDateField(name, { validate, label }) {
	const ref = React.useRef()
	const [touched, setTouched] = React.useState(false)

	const {
		register,
		setError,
		watch,
		clearError,
		setValue,
		formState
	} = useFormContext()

	// add a blur event to the fieldset
	// wrap the blur callback with a setTimeout
	// to ensure the state change happens at the right
	// stage of the event loop
	React.useEffect(() => {
		// store a refeence to the current node
		// other wise it could become stale by the time the
		// effect unmounts
		let timer
		const node = ref.current
		const onBlur = () => {
			timer = setTimeout(() => {
				if (node && !node.contains(document.activeElement)) {
					setTouched(true)
				}
			})
		}

		// make sure the node exists
		if (node && !touched) {
			node.addEventListener('blur', onBlur, true)
		}

		return () => {
			if (node) {
				node.removeEventListener('blur', onBlur, true)
			}
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [ref, touched])

	// register the date input
	React.useEffect(() => {
		register(
			{
				name
			},
			validate
		)
	}, [register, name, validate])

	// watch the date value for changes
	const date = watch(name)

	React.useEffect(() => {
		if (formState.isSubmitted) {
			setTouched(true)
		}
	}, [formState.isSubmitted])

	// react to the date value changes
	React.useEffect(() => {
		// does the date value match the required format
		const valid = datePattern.test(date)
		// does the date have any value
		const hasValue = !!date

		// if the date is invalid, and the fields have
		// been touched, set the errors
		if (!valid && ((hasValue && touched) || formState.isSubmitted)) {
			setError(name, 'invalid', `${label} requires a valid date`)
			return
		}

		if (valid) {
			clearError([name])
		}
	}, [date, name, setError, clearError, touched, formState.isSubmitted, label])

	return { date, ref, touched, setValue }
}

export default useDateField
