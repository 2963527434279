import * as React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import Node from '@/utils/Node'

function DashedLine({ className, as = 'div' }) {
	return <Node as={as} className={classNames('h-2 dashed-line', className)} />
}

DashedLine.propTypes = {
	className: string.isRequired,
	as: string
}

export default DashedLine
