import * as React from 'react'
import { useForm, FormContext } from 'react-hook-form/dist/react-hook-form.ie11'
import { number, string, func } from 'prop-types'
import DateField from '@/components/formFields/DateField'
import Button from '@/components/buttons/Button'
import Notification from '@/components/user/Notification'
import Link from '@/utils/Link'
import FormFooter from '@/utils/FormFooter'
import * as restAPI from '@/utils/endpoints'
import fetchApi from '@/utils/fetchApi'
import Text from '@/utils/Text'
import useFocusOnFirstInput from '@/hooks/useFocusOnFirstInput'
import useFormState from '@/hooks/useFormState'
import { datePattern } from '@/utils/constants'
import * as m from '@/utils/messages'

function Verify({ setView, step, id, setProgress, nextView }) {
	const formRef = React.useRef()

	const methods = useForm({
		mode: 'onChange'
	})

	const { watch, handleSubmit, errors, formState, setError } = methods

	useFocusOnFirstInput(formRef)

	// watch the dob field for changes
	const dateValue = watch('dob')

	// this will be used to handle any error messages
	// returned from calling the API... The default message
	// will be okay for
	const [submitError, setSubmitError] = React.useState(m.DOB_NOT_VALID)

	// submit form, handle api response
	const onSubmit = async data => {
		const response = await fetchApi(restAPI.PASSWORD_RESET(id), {
			method: 'POST',
			body: JSON.stringify(data)
		})

		const success = response.status === 'ok'
		//  the dob was correct, we can continue to the next stage
		if (success) {
			// update the view, so that the passwod component is rendered
			setView(nextView)
			return
		}

		// error handling

		if (response.status === 'error') {
			setSubmitError(m.DOB_NOT_VALID)
			setError('dob', 'invalid', true)
			return
		}

		// something was wrong, if the status is a string, display that
		// this might say "Invalid magic link"
		if (typeof response.status === 'string') {
			setSubmitError(response.status)
			return
		}

		// server error
		if (response.error) {
			setSubmitError(response.error.message || 'Error')
		}
	}

	// only show the submit errors if a submission has been
	// attempted, and we have some field errors, or a submitError message
	// and, only if the form is not currently being submitted
	const showFormErrors =
		formState.submitCount > 0 &&
		(!!Object.keys(errors).length || submitError) &&
		!formState.isSubmitting

	// update the loading state
	// based on whether the form is
	// currently submitting or not.
	useFormState(formState.isSubmitting)

	// update the form progress component
	// we should only do this once all of
	// the date is a valid format
	React.useEffect(() => {
		if (dateValue) {
			const hasError = !datePattern.test(dateValue) || showFormErrors
			setProgress(hasError ? step - 1 : step)
		}
	}, [errors, dateValue, setProgress, step, showFormErrors])

	return (
		<FormContext {...methods}>
			<form
				data-testid="verify-form"
				noValidate
				method="post"
				onSubmit={handleSubmit(onSubmit)}
				ref={formRef}
			>
				<div className="mx-auto max-w-login">
					{showFormErrors && (
						<Notification
							data-testid="form-errors"
							level="error"
							className="mb-lg"
						>
							{submitError}
						</Notification>
					)}
					<Text as="h1" className="text-lg mb-xl">
						{m.BACK_ON_TRACK}
					</Text>
					<Text className="mb-xl">{m.SECURITY_ENTER_DOB} </Text>
					<DateField
						name="dob"
						error={errors?.dob?.message}
						validate={{
							required: {
								value: true,
								message: m.DOB_REQUIRED
							}
						}}
					/>
				</div>
				<FormFooter>
					<Text as={Link} to="/" inline className="text-primary mr-lg">
						Cancel
					</Text>
					<Button
						data-testid="dob-submit"
						as="button"
						type="submit"
						disabled={formState.isSubmitting}
					>
						Next
					</Button>
				</FormFooter>
			</form>
		</FormContext>
	)
}

Verify.propTypes = {
	step: number.isRequired,
	id: string.isRequired,
	setView: func.isRequired,
	setProgress: func.isRequired,
	nextView: string.isRequired
}

export default Verify
