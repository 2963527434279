import { prefixWithZero } from '@/utils'
import { isLeapYear, parseISO } from 'date-fns'

/**
 *
 * @param {string} value
 * @param {number} length
 */
export function onChangeTest(value, length = 2) {
	return (value.length <= length && /^[0-9]+$/.test(value)) || value === ''
}

export function toNum(value) {
	return parseInt(value, 10)
}

export function getDaysInMonth(month, isLeap) {
	return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][
		month || 0
	]
}

export const fixDay = (day, month, year) => {
	if (year && year.toString().length === 4) {
		const leapYear = isLeapYear(parseISO(`${year}-01-01`))
		const maxDay = getDaysInMonth(toNum(month) - 1, leapYear)

		const newDay = !day.length || toNum(day) <= maxDay ? day : maxDay || day

		return prefixWithZero(newDay)
	}

	return day
}
