import { isLeapYear, parseISO } from 'date-fns'
import { prefixWithZero } from '@/utils'
import { onChangeTest, toNum, getDaysInMonth, fixDay } from './utils'

export default function reducer(state, { type, payload }) {
	switch (type) {
		case 'SET_DAY': {
			const maxDay = getDaysInMonth(
				toNum(state.month) - 1,
				isLeapYear(parseISO(`${state.year}-01-01`))
			)

			const num = toNum(payload)
			const valid =
				(onChangeTest(payload) && num <= maxDay) || payload.length === 0

			if (!valid) return state

			return {
				...state,
				day: num > 3 ? prefixWithZero(payload) : payload,
				maxDay
			}
		}

		case 'PAD_DAY': {
			return {
				...state,
				day: prefixWithZero(state.day)
			}
		}

		case 'SET_MONTH': {
			const num = toNum(payload)
			const valid = (onChangeTest(payload) && num <= 12) || payload.length === 0
			const month =
				num > 1 && valid
					? prefixWithZero(payload)
					: valid
					? payload
					: state.month

			const maxDay = getDaysInMonth(
				toNum(state.month) - 1,
				isLeapYear(parseISO(`${state.year}-${month}-01`))
			)

			return {
				...state,
				month,
				day: fixDay(state.day, month, state.year),
				maxDay
			}
		}

		case 'PAD_MONTH': {
			return {
				...state,
				month: prefixWithZero(state.month)
			}
		}

		// this will need updating in 1000 years!
		case 'SET_YEAR': {
			const valid = onChangeTest(payload, 4)
			const year = valid ? payload : state.year

			const maxDay = getDaysInMonth(
				toNum(state.month) - 1,
				isLeapYear(parseISO(`${year}-${state.month}-01`))
			)

			return {
				...state,
				year,
				day: fixDay(state.day, state.month, year),
				maxDay
			}
		}

		default: {
			throw new Error('action type missing')
		}
	}
}
