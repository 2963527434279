import * as React from 'react'
import { string, func, number, oneOfType, bool } from 'prop-types'
import TextInput from '@/components/formFields/TextInput'
import Text from '@/utils/Text'

const DateInput = React.forwardRef(
	(
		{
			label,
			id,
			name,
			placeholder,
			wrapperClass,
			value,
			error,
			onChange,
			onBlur,
			...rest
		},
		ref
	) => {
		return (
			<div className={wrapperClass}>
				<Text
					className="inline-block text-md mb-lg font-h-light"
					as="label"
					htmlFor={name}
				>
					{label}
				</Text>
				<TextInput
					ref={ref}
					type="text"
					as="input"
					name={name}
					id={id}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					error={error}
					showErrors={false}
					pattern="[0-9]*"
					inputMode="numeric"
					{...rest}
				/>
			</div>
		)
	}
)

DateInput.propTypes = {
	label: string.isRequired,
	id: string.isRequired,
	name: string.isRequired,
	placeholder: string.isRequired,
	wrapperClass: string,
	value: oneOfType([number, string]),
	onChange: func.isRequired,
	error: oneOfType([string, bool]),
	onBlur: func
}

export default DateInput
