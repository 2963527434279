import * as React from 'react'
import { string } from 'prop-types'
import Verify from '@/templates/Password/Verify'
import Layout from '@/container/Layout'
import FormProgress from '@/components/widgets/FormProgress/FormProgress'
import withValidResetUrl from '@/templates/Password/withValidResetUrl'
import Spinner from '@/utils/Spinner'
import { isBrowser } from '@/utils'

const ResetPassword = React.lazy(() =>
	import('@/templates/Password/ResetPassword')
)
const VERIFY_VIEW = 'VERIFY'
const PASSWORD_VIEW = 'PASSWORD'

function Reset({ id }) {
	const [view, setView] = React.useState(VERIFY_VIEW)
	const [progress, setProgress] = React.useState(0)
	const SHOW_VERIFY = view === VERIFY_VIEW
	const SHOW_PASSWORD = view === PASSWORD_VIEW

	const paths = [
		{ text: 'Verify', view: VERIFY_VIEW },
		{ text: 'Password', view: PASSWORD_VIEW }
	]

	return (
		<Layout className="form-screen pt-lg">
			<div className="w-full mx-auto">
				<FormProgress
					progress={progress}
					page={SHOW_VERIFY ? 1 : 2}
					labels={paths}
					className="mb-2xl"
				/>
			</div>
			{SHOW_VERIFY && (
				<Verify
					progress={progress}
					step={1}
					id={id}
					setView={setView}
					nextView={PASSWORD_VIEW}
					setProgress={setProgress}
				/>
			)}
			{isBrowser && (
				<React.Suspense
					fallback={
						<div className="fixed inset-0 flex items-center justify-center z-100">
							<Spinner />
						</div>
					}
				>
					{SHOW_PASSWORD && (
						<ResetPassword
							setProgress={setProgress}
							id={id}
							progress={progress}
							step={2}
						/>
					)}
				</React.Suspense>
			)}
		</Layout>
	)
}

Reset.propTypes = {
	id: string.isRequired
}

export default withValidResetUrl(Reset)
