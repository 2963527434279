import * as React from 'react'
import {
	number,
	string,
	arrayOf,
	shape,
	bool,
	func,
	node,
	object,
	oneOfType
} from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames'
import Tick from '@/icons/icon-avail-tick.svg'
import { isNotLast } from '@/utils'

import Text from '@/utils/Text'
import Circle from '@/utils/Circle'
import Node from '@/utils/Node'
import IconWrapper from '@/utils/IconWrapper'
import DashedLine from '@/utils/DashedLine'

const notLessThenZero = (page, total) => {
	const result = (page - 1) / (total - 1)

	return result < 0 ? 0 : result
}
function FormProgressNode({
	count,
	text,
	page,
	progress,
	showLabels = true,
	as,
	onClick,
	...rest
}) {
	return (
		<Node
			as={as}
			onClick={onClick}
			{...rest}
			className="relative z-30 flex flex-col items-center"
		>
			<Circle
				className={classNames(
					'w-xl transition-bg transition-500 h-xl md:w-48 w-xl md:h-48 text-white',
					{
						'bg-accent-20': page < count,
						'bg-accent': page >= count
					}
				)}
			>
				<AnimatePresence>
					{progress >= count ? (
						<IconWrapper
							icon={Tick}
							data-testid={`tick-${count}`}
							wrapperClassName="w-10 md:w-12 text-white"
						/>
					) : (
						<Text className="text-center text-white text-mob-md">{count}</Text>
					)}
				</AnimatePresence>
			</Circle>
			{showLabels && (
				<Text
					inline
					className={classNames(
						'hidden text-center uppercase tracking-wide md:inline-block absolute mt-md top-100 w-100 text-xs ie:left-50 ie:-x-50',
						{ 'text-accent': page >= count },
						{ 'text-grey-80': !page < count }
					)}
				>
					{text}
				</Text>
			)}
		</Node>
	)
}

FormProgressNode.propTypes = {
	count: number.isRequired,
	text: string.isRequired,
	page: number.isRequired,
	progress: number.isRequired,
	showLabels: bool,
	as: oneOfType([string, node, object, func]),
	onClick: func
}

function FormProgress({
	labels,
	className,
	progress = 0,
	page,
	allowLinks = true,
	showLabels = true
}) {
	const { length: total } = labels

	return (
		<div
			className={classNames(
				'flex items-center justify-between max-w-login w-full mx-auto relative z-20',
				className
			)}
		>
			{labels.map((item, index, { length }) => (
				<React.Fragment key={item.text}>
					<FormProgressNode
						page={page}
						text={item.text}
						count={index + 1}
						progress={progress}
						as={
							progress >= index + 1 && allowLinks && item.onClick
								? 'button'
								: 'div'
						}
						to={progress >= index + 1 && allowLinks ? item.link : null}
						showLabels={showLabels}
						onClick={item.onClick}
					/>

					{isNotLast(index, length - 1) && (
						<div className="relative flex-grow">
							<DashedLine className="h-2 text-accent-60 -z-1" />
						</div>
					)}
				</React.Fragment>
			))}
			<motion.div
				initial={{ scaleX: 0 }}
				animate={{ scaleX: notLessThenZero(page, total) }}
				transition={{ ease: 'easeOut', duration: 0.5 }}
				style={{ transformOrigin: 0, transform: 'scaleX(0)' }}
				className="absolute inset-y-0 left-0 z-10 w-full h-4 my-auto scale-x-0 bg-accent"
			/>
		</div>
	)
}

FormProgress.propTypes = {
	labels: arrayOf(
		shape({
			text: string,
			link: string,
			onClick: func
		})
	).isRequired,
	className: string,
	progress: number.isRequired,
	page: number.isRequired,
	allowLinks: bool,
	showLabels: bool
}

export default FormProgress
